import React, { useState, useEffect,} from 'react';
import {Card, Button, CardText, } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import HitchcockMovies from '../HitchcockMovies';

function HitchcockCollection() {
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [movies, setMovies] = useState([]);
  const { moviename: movieParam } = useParams();
  const [movieData, setMovieData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(() => {
    const handleBodyClick = (event) => {
      if (selectedTab && !event.target.closest('.expanded-content')) {
        setSelectedTab(null);
        setIsFullScreen(false);
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [selectedTab]);

  useEffect(() => {
    const fetchMovieData = async () => {
      try {
        const response = await fetch(`https://second-team-productions.onrender.com/hitchcockmovies`);
        if (!response.ok) {
          throw new Error('Failed to fetch movie data');
        }
        const data = await response.json();
        const filteredMovies = data.filter(movie => movie.moviename === 'YOUNG AND INNOCENT');
        if (filteredMovies.length > 0) {
          setMovieData(filteredMovies[0]);
          setIsLoaded(true);
        } else {
          throw new Error('Movie data not found');
        }
      } catch (error) {
        console.error('Error fetching movie data:', error);
        setError(error.message);
      }
    };

    fetchMovieData();
  }, [movieParam]);

  return (
    <div style={{ marginBottom: '7%',}}>
      <div>
      <div className='title-section'>
        <div>
          <Card className='align-items-center'>
          <img
          src={movieData && movieData.titleimage}
          alt="title image"
          style={{
            width: '75%',
            height: '75%',
          }}
        />
          </Card>
          <div> 
              <Card className= 'align-items-center'>
                <h2 className='title-font'
                style={{
                  marginTop: '15px',
                  color: 'grey',
                  // backgroundColor:'red',
                  textShadow: `
                  -1px -1px 0 #ff0000, 
                  1px -1px 0 #ff0000, 
                  -1px 1px 0 #ff0000, 
                  1px 1px 0 #ff0000
                ` 

                }} >
                the hitchcock collection
                </h2>
               <div style={{ display: 'flex',  gap: '1rem'  }}>
                </div>
              </Card>
           </div> 
        </div>
        </div>
      </div>
      <div>
      </div>
      <div className='row'
      style={{ 
        marginTop:'5%'
      }}> 
      </div>
      <div className="home-page-body" > 
      <div className="row align-items-center">
        <div className="row align-items-center">
        </div>
        <div className='container-fluid'>  
        <div className="row align-items-center">
              <HitchcockMovies movies={movies} setMovies={setMovies} title=" Hitchcock Movies" />
        </div>
      </div>
      </div>
      <br></br>
      <div> 
      </div> 
      </div>
    </div>
  );
}

export default HitchcockCollection;
