import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const EmdMovie = () => {
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await fetch('https://second-team-productions.onrender.com/test');
        if (!response.ok) {
          throw new Error('Failed to fetch movies');
        }
        const data = await response.json();
        const filteredMovies = data.filter(movie => movie.moviename !== 'SEVEN SAMURAI');
        setMovies(filteredMovies);
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };

    fetchMovies();
  }, []);

  return (
    <div className="d-flex flex-wrap " style={{ margin: '1.5%', padding: '1.5%' }}>
      {movies.map(movie => (
        <Card key={movie.id} className="custom-border col-2" style={{ margin: '1.5%', padding: '1.5%' }}>
          <Link to={`/test/${movie.moviename}`}>
            <Card.Img variant="top" src={movie.posterimage} alt={movie.moviename} className="card-img" />
          </Link>
        </Card>
      ))}
    </div>
  );
};

export default EmdMovie;
