import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardHeader, CardText, Carousel, CarouselItem, Collapse, ListGroup, ListGroupItem } from 'react-bootstrap';
import { BiLogoImdb } from "react-icons/bi";
import { ObjectTrack, drawBoundingBoxes } from '../utils';
import { ObjectTrackCards, createTrackCards } from '../cardsutils'; 

function MovieProfileTest() {
  const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { moviename: movieParam } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [movieData, setMovieData] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null); 
  const [savedTime, setSavedTime] = useState(0);
  const jsonData = movieData?.objectdata || {}; 
  const [videoInfo, setVideoInfo] = useState({ width: null, height: null, length: null });
  const [objectData, setObjectData] = useState(null);
  const [isElementsReady, setIsElementsReady] = useState(false);
  const [cardInfo, setCardInfo] = useState({ width: null, height: null,});
  const [tracks, setTracks] = useState([]);
  const [cardContent, setCardContent] = useState([]);

  const [activeSection, setActiveSection] = useState(null);

  const handleToggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const isSectionActive = (section) => {
    return activeSection === section;
  };

  const handleVideoPlay = () => {
    setIsPlaying(true);
    if (videoRef.current) {
      videoRef.current.currentTime = savedTime;
      videoRef.current.play();
    }
  };

  const handleExit = () => {
    if (videoRef.current) {
      setSavedTime(videoRef.current.currentTime);
      videoRef.current.pause();
      videoRef.current.removeAttribute('src');
      videoRef.current.load();
    }
    setIsPlaying(false);
    setCardContent([]); 
  };

  useEffect(() => {
    if (videoRef.current && canvasRef.current) {
      setIsElementsReady(true);
    }
  }, [videoRef.current, canvasRef.current]);
  
  useEffect(() => {
    const fetchMovieData = async () => {
      try {
        const responses = await Promise.all([
          fetch(`https://second-team-productions.onrender.com/movies/${movieParam}`),
          fetch(`https://second-team-productions.onrender.com/newmovies/${movieParam}`),
          fetch(`https://second-team-productions.onrender.com/hitchcockmovies/${movieParam}`),
          fetch(`https://second-team-productions.onrender.com/test/${movieParam}`)
        ]);
  
        responses.forEach(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
        });
  
        const data = await Promise.all(responses.map(response => response.json()));
        let combinedData = [...data[0], ...data[1], ...data[2]];
        const validData = combinedData.length > 0 ? combinedData[0] : null;
  
        if (validData) {
          setMovieData(validData);
  
          if (typeof validData?.objectdata === 'string') {
            const objectDataUrl = validData.objectdata;
  
            try {
              const objectDataResponse = await fetch(objectDataUrl);
              if (objectDataResponse.ok) {
                const objectDataJson = await objectDataResponse.json();
                setObjectData(objectDataJson);
              } else {
                console.error('Failed to fetch object data. Status:', objectDataResponse.status);
              }
            } catch (fetchError) {
              console.error('Error fetching object data:', fetchError);
            }
          } else {
            console.log('Object Data (already an object):', validData?.objectdata);
            setObjectData(validData?.objectdata);
          }
  
          setIsLoaded(true);
        } else {
          throw new Error('No valid movie data found');
        }
      } catch (error) {
        console.error('Error fetching movie data:', error);
        setError(error.message);
      }
    };
  
    fetchMovieData();
  }, [movieParam]);

  useEffect(() => {

    if (!isPlaying) return; 

    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (!video) {
      console.error('Video is not available');
      return;
    }

    if (!canvas) {
      console.error('Canvas is not available');
      return;
    }

    const handleLoadedMetadata = () => {
  
      const videoWidth = video.videoWidth || videoInfo.width;
      const videoHeight = video.videoHeight || videoInfo.height;

      setVideoInfo({ width: videoWidth, height: videoHeight, length: video.duration });

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('Failed to get 2D context');
        return;
      }
      

      if (objectData?.annotation_results?.[0]?.object_annotations) {
        const labelsToExclude = ["building", "lighting", "top", "Nicholas Ortiz", "clothing", "tire", "wheel", "hat", "curtain", "necklace", "street light", "luggage & bags", "packaged goods", "footware"];
    
        const tracks = objectData.annotation_results[0].object_annotations
            .filter(data => !labelsToExclude.includes(data.entity.description))
            .map((data) => {
                return new ObjectTrack(data, videoWidth, videoHeight);
            }) || [];

        const handlePause = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          drawBoundingBoxes(tracks, ctx, video);  
          if (document.fullscreenElement) {
            document.exitFullscreen().catch((err) => console.error("Error exiting fullscreen:", err));
          }
        };

        const handlePlay = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height); 
      };

        video.addEventListener('pause', handlePause);
        video.addEventListener('play', handlePlay);

        return () => {
          video.removeEventListener('pause', handlePause);
          video.addEventListener('play', handlePlay);
        };
      } else {
        console.error('Object Data or object_annotations is not available.');
      }
    };

    if (video.readyState >= 1) {
      handleLoadedMetadata();
    } else {
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
}, [isPlaying, objectData, videoRef, canvasRef]);



useEffect(() => {
  if (!isPlaying) return; 
  const video = videoRef.current;

  if (!video) {
    console.error('Video for Cards is not available');
    return;
  }

  const handleLoadedMetadata = () => {
    const videoWidth = video.videoWidth || videoInfo.width;
    const videoHeight = video.videoHeight || videoInfo.height;

    if (!videoInfo.width && !videoInfo.height) {
      setVideoInfo({
        width: videoWidth,
        height: videoHeight,
        length: video.duration,
      });
    }


    const cardWidth = 200;
    const cardHeight = 200;

    setCardInfo({ width: cardWidth, height: cardHeight });

    const labelsToExclude = ["building", "lighting", "top", "Nicholas Ortiz", "clothing", "tire", "wheel", "hat", "curtain", "necklace", "street light", "luggage & bags", "packaged goods", "footware"];

    const isValidObject = (obj) => {
      const hasValidEntity = obj?.entity && obj.entity.description && obj.entity.entity_id;
      const hasValidFrames = Array.isArray(obj?.frames) && obj.frames.length > 0;
      const hasValidSegment = obj?.segment && obj.segment.start_time_offset && obj.segment.end_time_offset;
      
      return hasValidEntity && hasValidFrames && hasValidSegment;
    };

    const validData = objectData.annotation_results[0].object_annotations.filter(data => {
      if (!isValidObject(data)) {
        console.warn("Invalid object data:", data);
        return false;
      }

      if (labelsToExclude.includes(data.entity.description)) {
        return false;
      }
      return true;
    });
    
    const cardTracks = validData.map((data) => {
      return new ObjectTrackCards(data, cardWidth, cardHeight);
    }).filter(track => track !== null);  

    setTracks(cardTracks);

    const handlePause = () => {
      createTrackCards({ cardTracks, video, setCardContent });
      if (document.fullscreenElement) {
        document.exitFullscreen().catch((err) => console.error("Error exiting fullscreen:", err));
      }
  };
  
  const handlePlayEvent = () => {
    handlePlay();
};

  const handlePlay = () => {
    setCardContent([]); 
};

    video.addEventListener('pause', handlePause);
    video.addEventListener('play', handlePlayEvent);

    return () => {
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('play', handlePlayEvent);
    };
  };

  if (video.readyState >= 1) {
    handleLoadedMetadata();
  } else {
    video.addEventListener('loadedmetadata', handleLoadedMetadata);
  }

  return () => {
    video.removeEventListener('loadedmetadata', handleLoadedMetadata);
  };
}, [isPlaying, objectData, videoRef, videoInfo]);

  return (
    <div>
      {error ? (
        <div>Error: {error}</div>
      ) : (
        <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {!isPlaying ? (
               <div> 
               <div className='row align-items-center' style={{backgroundColor: 'black', }}>
               <div className='col-5'>
                 <div className='page-content'>
                   <Card> 
                     <h2 className='title-font' 
                     style={{ color: movieData && movieData.fontColor,
                              textShadow: movieData && movieData.textShadow
                               }}> 
                     {movieData && movieData.moviename}
                     </h2>
                     <div style={{ display: 'flex', gap: '1rem'  }}>  
                    {/* {movieData && movieData.rating && (
                    <img  
                     src={movieData.rating} 
                     alt='[Movie Rating]' 
                     className="img-fluid" 
                     style={{
                     width: '3.2vw',
                     height: '3.2vh',
                     marginRight: '12px',
                     }}
                      />
                       )}  */}
                       <Card.Text>{movieData && movieData.year}</Card.Text>
                       <Card.Text>{movieData && movieData.runtime}</Card.Text>
                     </div>
                     <Card.Text style={{ marginTop: '2vmin' }}>
                     {movieData && movieData.moviedescription}
                     </Card.Text>
                     <Button className="process-card-text hover-brighten" 
                       style={{
                         position: 'relative',
                         width: '20vmin',
                         height: '5.5vmin',
                         marginLeft: '10.5vmin',
                         marginTop: '2.5vmin',
                         transform: 'translate(-50%, -50%)',
                         fontSize: '2vmin',
                         backgroundColor: 'rgb(67, 66, 66)',
                         border: 'none',
                         cursor: 'pointer',
                       }}

                       onClick={handleVideoPlay}
                     >
                      
                       ▶️ Play
                     </Button>
                     <div className="card-body-page">
                       <div className='row'>
                         <div className='col-4'>
                           <h4>Director</h4>
                           <p>
                           {movieData && movieData.director}
                           </p>
                         </div>
                         <div className='col-8'>
                           <h4>Cast</h4>
                           <p className='row'>
                           {movieData && movieData.cast1}, {movieData && movieData.cast2}
                           </p>
                        
                         </div>
                       </div>
                     </div>
                   </Card>
                 </div>
               </div>
               <div className='col-6' style={{ position: 'relative', backgroundColor: 'black' }}>
               {movieData && movieData.mainimage && (
               <img
                src={movieData.mainimage}
                alt="main image"
                style={{
                width: '100%',
                height: '100%',
                marginBottom: '1%',
                
                maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                transition: 'mask-image 0.3s ease',
                 }}
                onClick={handleVideoPlay}
                />
                )}
              </div>
            <div>
          </div>
                </div>
                <div>
                      <h2 className='section-title justify-content-center'
                       style={{
                        marginTop: '2%',
                        marginBottom: '1%',
                      }}>About</h2>
                      </div>
               <Card className="container card-body"
                    style={{
                      width: '100%',
                      height: '100%',
                      marginTop: '2%',
                      marginBottom: '1%',
                    }}>
                    <div>
                      <div>
          <Card className='about-card'> 
        <div className='row align-items-center'>
          <div className='col-8'> 
          <div> 
          <Card style={{padding:'2%',}}>
            <CardHeader>
            <h3 style={{marginTop: '2%',}}>Director</h3>
            </CardHeader>
              <h4>
              {movieData && movieData.director}
              </h4>
              <Card.Text>
              {movieData && movieData.directorbio}
              </Card.Text>
              <div className='row align-items-center'>
              <div className='col-6'>
              <CardText>
                <h4>
                  Resume
                </h4>
                <ListGroup>
                  <ListGroupItem>
                  {movieData && movieData.directorresume1}
                  </ListGroupItem>
                  <ListGroupItem>
                  {movieData && movieData.directorresume2}
                  </ListGroupItem>
                  <ListGroupItem>
                  {movieData && movieData.directorresume3}
                  </ListGroupItem>
                </ListGroup>
              </CardText>
              </div>
              <div className='col-6'>
              {movieData && movieData.directorcontact && (
              <a href={`mailto:${movieData.directorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}
              <a href="https://www.imdb.com/name/nm0145336/?ref_=fn_al_nm_1">
                 <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
              
                </div>
            </Card>
            </div>
            </div>
            <div className='col-4' style={{position:'relative',}}>
            {movieData && movieData.directorimage && (
              <img  
                src={movieData.directorimage} 
                alt='William Castle' 
                className="img-fluid" 
                style={{
                  width: '60%',
                  height: '60%',
                  maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                  WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                  transition: 'mask-image 0.3s ease',
                }}
            
                />
                )}
            </div>
            </div>
            </Card>
            </div>
    <div className='card-body-cast'> 
    <CardHeader>
    <h3 style={{marginTop: '2%',}}>Cast</h3>
    </CardHeader>
         <div>
          <Carousel interval={null}>
            <CarouselItem>
            <Card> 
        <div className='row align-items-center'>
          <div className='col-7'> 
          <div> 
          <Card style={{padding:'2%',}}>
              <h4>
              {movieData && movieData.cast1}
              </h4>
              <Card.Text>
              {movieData && movieData.cast1bio}
              </Card.Text>
              <div className='row align-items-center'>
              <div className='col-6'>
              <CardText>
                <h4>
                  Resume
                </h4>
                <ListGroup>
                  <ListGroupItem>
                  {movieData && movieData.cast1resume1}
                  </ListGroupItem>
                  <ListGroupItem>
                  {movieData && movieData.cast1resume2}
                  </ListGroupItem>
                  <ListGroupItem>
                  {movieData && movieData.cast1resume3}
                  </ListGroupItem>
                </ListGroup>
              </CardText>
              </div>
              <div className='col-6'>
              {movieData && movieData.cast1contact && (
              <a href={`mailto:${movieData.cast1contact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}   
              </div>
                </div>
            </Card>
            </div>
            </div>
            <div className='col-4' style={{position:'relative',}}>
            {movieData && movieData.cast1image && (
              <img
                src={movieData.cast1image}
                alt="Cast1 Image"
                style={{
                width: '50%',
                height: '50%',
                marginBottom: '1%',
                marginLeft: '20%',
                maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                transition: 'mask-image 0.3s ease',
                 }}
                
                /> 
            )}
                <a href="https://www.imdb.com/name/nm0001637/?ref_=fn_al_nm_1">
                 <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
            </div>
            </div>
            </Card>
            </CarouselItem>
            <CarouselItem>
            <Card> 
        <div className='row align-items-center'>
          <div className='col-7'> 
          <div> 
          <Card style={{padding:'2%',}}>
              <h4>
              {movieData && movieData.cast2}
              </h4>
              <Card.Text>
              {movieData && movieData.cast2bio}
              </Card.Text>
              <div className='row align-items-center'>
              <div className='col-6'>
              <CardText>
                <h4>
                  Resume
                </h4>
                <ListGroup>
                  <ListGroupItem>
                  {movieData && movieData.cast2resume1}
                  </ListGroupItem>
                  <ListGroupItem>
                  {movieData && movieData.cast2resume2}
                  </ListGroupItem>
                  <ListGroupItem>
                  {movieData && movieData.cast2resume3}
                  </ListGroupItem>
                </ListGroup>
              </CardText>
              </div>
              <div className='col-6'>
              {movieData && movieData.cast2contact && (
              <a href={`mailto:${movieData.cast2contact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
              </div>
                </div>
            </Card>
            </div>
            </div>
            <div className='col-4' style={{position:'relative',}}>
            {movieData && movieData.cast2image && (
              <img  
                src={movieData.cast2image} 
                alt='Carol Ohmart' 
                className="img-fluid" 
                style={{
                  width: '45%',
                  height: '45%',
                  
                  maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                  WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                  transition: 'mask-image 0.3s ease',
                }}
                />
            )}
                 <a href="https://www.imdb.com/name/nm0645120/?ref_=fn_al_nm_1">
                 <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
            </div>
            </div>
            </Card>
            </CarouselItem>
          </Carousel>
            </div>
            </div>
            <div> 
            </div>
            <div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('producers')}>
    <h3 style={{  marginTop: '2%' }}>Producers</h3>
  </Card.Header>
  <Collapse in={isSectionActive('producers')}>
    <div id="producers-section">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>executive producer/ showrunner</h3>
                    <h4>
                    {movieData && movieData.exproducer}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.executiveproducerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.executiveproducerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.executiveproducerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.executiveproducerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
         {movieData && movieData.executiveproducercontact && (
              <a href={`mailto:${movieData.executiveproducercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
         </div>
        </div>        
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>co-executive producer</h3>
                    <h4>
                    {movieData && movieData.coexproducer}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.coexecutiveproducerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.coexecutiveproducerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.coexecutiveproducerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.executiveproducerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.coexecutiveproducercontact && (
              <a href={`mailto:${movieData.coexecutiveproducercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}     
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('production')}>
    <h3 style={{ marginTop: '2%' }}>Production</h3>
  </Card.Header>
  <Collapse in={isSectionActive('production')}>
    <div id="production">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>UPM</h3>
                    <h4>{movieData && movieData.unitproductionmanager}</h4>
                    <Card.Text>
                    {movieData && movieData.unitproductionmanagerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.unitproductionmanagerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.unitproductionmanagerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.unitproductionmanagerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.unitproductionmanagercontact && (
              <a href={`mailto:${movieData.unitproductionmanagercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Production coordinator</h3>
                    <h4>
                    {movieData && movieData.productioncoordinator}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.productioncoordinatorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.productioncoordinatorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.productioncoordinatorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.productioncoordinatorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.productioncoordinatorcontact && (
              <a href={`mailto:${movieData.productioncoordinatorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}     
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('accounting')}>
    <h3 style={{marginTop: '2%' }}>accounting</h3>
  </Card.Header>
  <Collapse in={isSectionActive('accounting')}>
    <div id="accounting">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Production Accountant</h3>
                    <h4>
                    {movieData && movieData.productionaccountant}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.productionaccountantbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.productionaccountantresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.productionaccountantresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.productionaccountantresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.productionaccountantcontact && (
              <a href={`mailto:${movieData.productionaccountantcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>1st assistant accountant</h3>
                    <h4>
                    {movieData && movieData['1stassistantaccountant']}
                    </h4>
                    <Card.Text>
                    {movieData && movieData['1stassistantaccountantbio']}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData['1stassistantaccountantresume1']}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData['1stassistantaccountantresume2']}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData['1stassistantaccountantresume3']}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData['1stassistantaccountantcontact'] && (
              <a href={`mailto:${movieData['1stassistantaccountantcontact']}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}      
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('writers')}>
    <h3 style={{ marginTop: '2%' }}>Writers</h3>
  </Card.Header>
  <Collapse in={isSectionActive('writers')}>
    <div id="writers-section">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Writer 1</h3>
                    <h4>
                    {movieData && movieData.writer1}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.writer1bio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.writer1resume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.writer1resume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.writer1resume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.writer1contact && (
              <a href={`mailto:${movieData.writer1contact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                    
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Writer 2</h3>
                    <h4>
                    {movieData && movieData.writer2}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.writer2bio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.writer2resume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.writer2resume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.writer2resume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.writer2contact && (
              <a href={`mailto:${movieData.writer2contact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>
<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('assistant directors')}>
    <h3 style={{marginTop: '2%' }}>Assistant Directors</h3>
  </Card.Header>
  <Collapse in={isSectionActive('assistant directors')}>
    <div id="assistant-directors">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>1st AD </h3>
                    <h4>
                    {movieData && movieData['1stad']}
                    </h4>
                    <Card.Text>
                    {movieData && movieData['1stadbio']}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData['1stadresume1']}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData['1stadresume2']}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData['1stadresume3']}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData['1stadcontact'] && (
              <a href={`mailto:${movieData['1stadcontact']}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}   
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>2nd AD</h3>
                    <h4>
                    {movieData && movieData['2ndad']}
                    </h4>
                    <Card.Text>
                    {movieData && movieData['2ndadbio']}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData['2ndadresume1']}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData['2ndadresume2']}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData['2ndadresume3']}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData['2ndadcontact'] && (
              <a href={`mailto:${movieData['2ndadcontact']}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('camera')}>
    <h3 style={{ marginTop: '2%' }}>camera</h3>
  </Card.Header>
  <Collapse in={isSectionActive('camera')}>
    <div id="camera">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>cam op 1</h3>
                    <h4>
                    {movieData && movieData.camop1}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.camop1bio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.camop1resume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.camop1resume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.camop1resume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.camop1contact && (
              <a href={`mailto:${movieData.camop1contact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}     
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>cam op 2</h3>
                    <h4>
                    {movieData && movieData.camop2}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.camop2bio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.camop2resume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.camop2resume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.camop2resume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.camop2contact && (
              <a href={`mailto:${movieData.camop2contact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('sound')}>
    <h3 style={{ marginTop: '2%' }}>sound</h3>
  </Card.Header>
  <Collapse in={isSectionActive('sound')}>
    <div id="sound">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Sound Mixer</h3>
                    <h4>
                    {movieData && movieData.soundmixer}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.soundmixerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.soundmixerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.soundmixerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.soundmixerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.soundmixercontact && (
              <a href={`mailto:${movieData.soundmixercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Boom Operator</h3>
                    <h4>
                    {movieData && movieData.boomoperator}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.boomoperatorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.boomoperatorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.boomoperatorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.boomoperatorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.boomoperatorcontact && (
              <a href={`mailto:${movieData.boomoperatorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('locations')}>
    <h3 style={{marginTop: '2%' }}>Locations</h3>
  </Card.Header>
  <Collapse in={isSectionActive('locations')}>
    <div id="locations">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>location manager</h3>
                    <h4>
                    {movieData && movieData.locationmanager}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.locationmanagerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.locationmanagerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.locationmanagerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.locationmanagerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.locationmanagercontact && (
              <a href={`mailto:${movieData.locationmanagercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>assistant location manager</h3>
                    <h4>
                    {movieData && movieData.assistantlocationmanager}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.assistantlocationmanagerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.assistantlocationmanagerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.assistantlocationmanagerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.assistantlocationmanagerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.assistantlocationmanagercontact && (
              <a href={`mailto:${movieData.assistantlocationmanagercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}   
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('grip')}>
    <h3 style={{ marginTop: '2%' }}>grip</h3>
  </Card.Header>
  <Collapse in={isSectionActive('grip')}>
    <div id="grip">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Key Grip</h3>
                    <h4>
                    {movieData && movieData.keygrip}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.keygripbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.keygripresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.keygripresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.keygripresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.keygripcontact && (
              <a href={`mailto:${movieData.keygripcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Best Boy grip</h3>
                    <h4>
                    {movieData && movieData.bestboygrip}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.bestboygripbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.bestboygripresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.bestboygripresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.bestboygripresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.bestboygripcontact && (
              <a href={`mailto:${movieData.bestboygripcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('electric')}>
    <h3 style={{ marginTop: '2%' }}>electric</h3>
  </Card.Header>
  <Collapse in={isSectionActive('electric')}>
    <div id="electric">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>gaffer</h3>
                    <h4>{movieData && movieData.gaffer}</h4>
                    <Card.Text>
                    {movieData && movieData.gafferbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.gafferresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.gafferresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.gafferresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.gaffercontact && (
              <a href={`mailto:${movieData.gaffercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>best boy electric</h3>
                    <h4>
                    {movieData && movieData.bestboyelectric}
                    </h4>
                    <Card.Text>
                    {movieData && movieData.bestboyelectricbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.bestboyelectricresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.bestboyelectricresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.bestboyelectricresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.bestboyelectriccontact && (
              <a href={`mailto:${movieData.bestboyelectriccontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('transportation')}>
    <h3 style={{ marginTop: '2%' }}>transportation</h3>
  </Card.Header>
  <Collapse in={isSectionActive('transportation')}>
    <div id="transportation">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>transpo coordinator</h3>
                    <h4>{movieData && movieData.transpocoordinator}</h4>
                    <Card.Text>
                    {movieData && movieData.transpocoordinatorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.transpocoordinatorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.transpocoordinatorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.transpocoordinatorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.transpocoordinatorcontact && (
              <a href={`mailto:${movieData.transpocoordinatorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Picture car coordinator</h3>
                    <h4>J{movieData && movieData.picturecarcoordinator}</h4>
                    <Card.Text>
                    {movieData && movieData.picturecarcoordinatorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.picturecarcoordinatorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.picturecarcoordinatorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.picturecarcoordinatorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.picturecarcoordinatorcontact && (
              <a href={`mailto:${movieData.picturecarcoordinatorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('costumes')}>
    <h3 style={{ marginTop: '2%' }}>costumes</h3>
  </Card.Header>
  <Collapse in={isSectionActive('costumes')}>
    <div id="costumes">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Key set costumer</h3>
                    <h4>{movieData && movieData.keysetcostumer}</h4>
                    <Card.Text>
                    {movieData && movieData.keysetcostumerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.keysetcostumerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.keysetcostumerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.keysetcostumerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.keysetcostumercontact && (
              <a href={`mailto:${movieData.keysetcostumercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}     
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>buyer</h3>
                    <h4>{movieData && movieData.buyer}</h4>
                    <Card.Text>
                    {movieData && movieData.buyerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.buyerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.buyerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.buyerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.buyercontact && (
              <a href={`mailto:${movieData.buyercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('hair-and-makeup')}>
    <h3 style={{ marginTop: '2%' }}>hair & makeup</h3>
  </Card.Header>
  <Collapse in={isSectionActive('hair-and-makeup')}>
    <div id="hair-and-makeup'">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>hair and make up dept. head</h3>
                    <h4>{movieData && movieData.hairandmakeupdepthead}</h4>
                    <Card.Text>
                    {movieData && movieData.hairandmakeupdeptheadbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.hairandmakeupdeptheadresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.hairandmakeupdeptheadresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.hairandmakeupdeptheadresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.hairandmakeupdeptheadcontact && (
              <a href={`mailto:${movieData.hairandmakeupdeptheadcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>Key hair & make up</h3>
                    <h4>{movieData && movieData.keyhairandmakeup}</h4>
                    <Card.Text>
                    {movieData && movieData.keyhairandmakeupbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.keyhairandmakeupresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.keyhairandmakeupresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.keyhairandmakeupresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.keyhairandmakeupcontact && (
              <a href={`mailto:${movieData.keyhairandmakeupcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('set-dec')}>
    <h3 style={{marginTop: '2%' }}>set dec</h3>
  </Card.Header>
  <Collapse in={isSectionActive('set-dec')}>
    <div id="set-dec'">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>set decorator</h3>
                    <h4>{movieData && movieData.setdecorator}</h4>
                    <Card.Text>
                    {movieData && movieData.setdecoratorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.setdecoratorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.setdecoratorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.setdecoratorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.setdecoratorcontact && (
              <a href={`mailto:${movieData.setdecoratorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>lead man</h3>
                    <h4>{movieData && movieData.leadman}</h4>
                    <Card.Text>
                    {movieData && movieData.leadmanbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.leadmanresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.leadmanresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.leadmanresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.leadmancontact && (
              <a href={`mailto:${movieData.leadmancontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('art-department')}>
    <h3 style={{ marginTop: '2%' }}>art department</h3>
  </Card.Header>
  <Collapse in={isSectionActive('art-department')}>
    <div id="art-department">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>production designer</h3>
                    <h4>{movieData && movieData.productiondesigner}</h4>
                    <Card.Text>
                    {movieData && movieData.productiondesignerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.productiondesignerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.productiondesignerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.productiondesignerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.productiondesignercontact && (
              <a href={`mailto:${movieData.productiondesignercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>art director</h3>
                    <h4>{movieData && movieData.artdirector}</h4>
                    <Card.Text>
                    {movieData && movieData.artdirectorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.artdirectorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.artdirectorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.artdirectorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.artdirectorcontact && (
              <a href={`mailto:${movieData.artdirectorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>
<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('music')}>
    <h3 style={{ marginTop: '2%' }}>music</h3>
  </Card.Header>
  <Collapse in={isSectionActive('music')}>
    <div id="music">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>music supervisor</h3>
                    <h4>{movieData && movieData.musicsupervisor}</h4>
                    <Card.Text>
                    {movieData && movieData.musicsupervisorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.musicsupervisorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.musicsupervisorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.musicsupervisorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.musicsupervisorcontact && (
              <a href={`mailto:${movieData.musicsupervisorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}  
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>composer/ editor</h3>
                    <h4>{movieData && movieData.composer}</h4>
                    <Card.Text>
                    {movieData && movieData.composerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.composerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.composerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.composerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.composercontact && (
              <a href={`mailto:${movieData.composercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>
<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('spfx')}>
    <h3 style={{ marginTop: '2%' }}>spfx</h3>
  </Card.Header>
  <Collapse in={isSectionActive('spfx')}>
    <div id='spfx'>
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>spfx coordinator</h3>
                    <h4>{movieData && movieData.spfxcoordinator}</h4>
                    <Card.Text>
                    {movieData && movieData.spfxcoordinatorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.spfxcoordinatorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.spfxcoordinatorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.spfxcoordinatorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.spfxcoordinatorcontact && (
              <a href={`mailto:${movieData.spfxcoordinatorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>SPFX Buyer</h3>
                    <h4>{movieData && movieData.spfxbuyer}</h4>
                    <Card.Text>
                    {movieData && movieData.spfxbuyerbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.spfxbuyerresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.spfxbuyerresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.spfxbuyerresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.spfxbuyercontact && (
              <a href={`mailto:${movieData.spfxbuyercontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}    
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Producer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

<div className='card-body-cast'>
<Card>
  <Card.Header onClick={() => handleToggleSection('post-production')}>
    <h3 style={{ marginTop: '2%' }}>Post production</h3>
  </Card.Header>
  <Collapse in={isSectionActive('post-production')}>
    <div id="post-production'">
      <Carousel interval={null}>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>editor</h3>
                    <h4>{movieData && movieData.editor}</h4>
                    <Card.Text>
                    {movieData && movieData.editorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.editorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.editorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.editorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.editorcontact && (
              <a href={`mailto:${movieData.editorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}   
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 1'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <div className='row align-items-center'>
              <div className='col-7'>
                <div>
                  <Card style={{ padding: '2%' }}>
                    <h3>assistant editor</h3>
                    <h4>{movieData && movieData.assistanteditor}</h4>
                    <Card.Text>
                    {movieData && movieData.assistanteditorbio}
                    </Card.Text>
                    <div className='row align-items-center'>
      <div className='col-6'>
      <CardText>
        <h4>
          Resume
        </h4>
        <ListGroup>
          <ListGroupItem>
          {movieData && movieData.assistanteditorresume1}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.assistanteditorresume2}
          </ListGroupItem>
          <ListGroupItem>
          {movieData && movieData.assistanteditorresume3}
          </ListGroupItem>
        </ListGroup>
      </CardText>
      </div>
      <div className='col-6'>
      {movieData && movieData.assistanteditorcontact && (
              <a href={`mailto:${movieData.assistanteditorcontact}`}>
              <Button className="process-card-text hover-brighten" 
              style={{
               fontSize: '2.5vmin',
                backgroundColor: 'rgb(67, 66, 66)',
                border: 'none',
                cursor: 'pointer',
                marginLeft: '30%',
              }}
               >
                    <h4 style={{ margin: "1%" }}>
                      Contact
                    </h4>
                  </Button>
                </a>
              )}   
      </div>
        </div>
                  </Card>
                </div>
              </div>
              <div className='col-4' style={{ position: 'relative' }}>
                {/* <img
                  src={Nick}
                  alt='Writer 2'
                  className="img-fluid"
                  style={{
                    width: '50%',
                    height: '50%',
                    maskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    WebkitMaskImage: 'linear-gradient(to right, transparent, white 20%, white 80%, transparent)',
                    transition: 'mask-image 0.3s ease',
                  }}
                /> */}
                <a href="https://www.imdb.com/name/nm13929885/">
                  <BiLogoImdb style={{ color: 'gold', fontSize: '4.5vmin' }} />
                </a>
              </div>
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
    </div>
  </Collapse>
</Card>
</div>

                    <div>
                    </div>
                    </div>
            </Card>
            <Card className="container"
                    style={{
                      width: '100%',
                      height: '100%',
                      marginTop: '10%',
                      marginBottom: '10%',
                     
                    }}>
                    <div>
                      <div>
                      
                      </div>
                    <div >
                    </div>
                    </div>
                </Card>
            </div>
            ) : (
              <div >
                <Button className="process-card-text hover-brighten-back"
                  onClick={handleExit}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: 'rgb(67, 66, 66)',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    fontSize: '16px'
                  }}
                >
                  Back
                </Button>
                <div 
                 style={{
                  position: 'relative',
                  display: 'inline-block',
                  backgroundColor: 'black', 
                  
                }}>
                <video 
                  controls
                  autoPlay
                  style={{ backgroundColor: 'black',   width: '100%',
                  height: '100%',  display: 'block' }}
                  ref={videoRef}
                >
                  <source src={movieData?.fullmovie} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
                <canvas
                    ref={canvasRef}
                    style={{
                      position: 'absolute',
                      zIndex:'2',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      pointerEvents: 'none'
                    }}
                  />
                  </div>
                  <div>
            <div>
                <div className='d-flex flex-wrap justify-content-center'>
                    {cardContent.length > 0 ? (
                        cardContent.map((content, index) => (
                            <Card key={index} className="custom-shop-border col-3" style={{ margin: '2vmin' }}>
                                <Card.Body>
                                    {content}
                                    <Button className="process-card-text hover-brighten"
                          style={{
                            position: 'relative', 
                            marginLeft: '6.5vmin',
                            marginTop: '4.5vmin',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'rgb(67, 66, 66)',
                            border: 'none',
                            cursor: 'pointer',
                            display: 'flex',       
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          
                        >
                          <h5 style={{ margin: 0 }} >add to cart</h5>
                         
                        </Button>
                                </Card.Body>
                            </Card>
                        ))
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MovieProfileTest;