class ObjectTrack {
    constructor(objectData, videoWidth, videoHeight) {
        this.name = objectData.entity.description;
        this.startTime = nullableTimeOffsetToSeconds(objectData.segment.start_time_offset);
        this.endTime = nullableTimeOffsetToSeconds(objectData.segment.end_time_offset);
        this.confidence = objectData.confidence;
        this.videoHeight = videoHeight;
        this.videoWidth = videoWidth;

        this.frames = objectData.frames.map(frame => {
            const left = frame.normalized_bounding_box?.left || 0;
            const top = frame.normalized_bounding_box?.top || 0;
            const right = frame.normalized_bounding_box?.right || 0;
            const bottom = frame.normalized_bounding_box?.bottom || 0;

            const x = left * videoWidth;
            const y = top * videoHeight;
            const width = (right - left) * videoWidth;
            const height = (bottom - top) * videoHeight;

            return {
                box: {
                    x,
                    y,
                    width,
                    height
                },
                timeOffset: nullableTimeOffsetToSeconds(frame.time_offset)
            };
        });
    }

    hasFramesForTime(seconds) {
        return this.startTime <= seconds && this.endTime >= seconds;
    }

    mostRecentRealBoundingBox(seconds) {
        for (let i = 0; i < this.frames.length; i++) {
            if (this.frames[i].timeOffset > seconds) {
                return i > 0 ? this.frames[i - 1].box : null;
            }
        }
        return null;
    }

    mostRecentInterpolatedBoundingBox(seconds) {
        for (let i = 0; i < this.frames.length; i++) {
            if (this.frames[i].timeOffset > seconds) {
                if (i > 0) {
                    const startBox = this.frames[i - 1];
                    const endBox = this.frames[i];
                    const timeDeltaRatio = (seconds - startBox.timeOffset) / (endBox.timeOffset - startBox.timeOffset);

                    return {
                        x: startBox.box.x + (endBox.box.x - startBox.box.x) * timeDeltaRatio,
                        y: startBox.box.y + (endBox.box.y - startBox.box.y) * timeDeltaRatio,
                        width: startBox.box.width + (endBox.box.width - startBox.box.width) * timeDeltaRatio,
                        height: startBox.box.height + (endBox.box.height - startBox.box.height) * timeDeltaRatio
                    };
                } else {
                    return null;
                }
            }
        }
        return null;
    }

    currentBoundingBox(seconds, interpolate = true) {
        return interpolate ? this.mostRecentInterpolatedBoundingBox(seconds) : this.mostRecentRealBoundingBox(seconds);
    }
}

export { ObjectTrack };

export function drawBoundingBoxes(tracks, ctx, video) {

    if (!validateObjectTracks(tracks)) {
        console.error('Invalid tracks data. Cannot proceed with drawing.');
        return;
    }

    if (!ctx || typeof ctx.clearRect !== 'function') {
        console.error('Invalid canvas context:', ctx);
        return;
    }

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); 

    const currentTime = video.currentTime;

    tracks.forEach(track => { 
        if (track instanceof ObjectTrack) {
            if (track.hasFramesForTime(currentTime)) {
                const boundingBox = track.currentBoundingBox(currentTime);
                if (boundingBox) {
                    drawBoundingBox(boundingBox, track.name, ctx);
                    
                }
            }
        } else {
            console.warn('Object is not an instance of ObjectTrack:', track);
        }
    });
}

function validateObjectTracks(tracks) {
    if (!Array.isArray(tracks)) {
        return false;
    }

    return tracks.every(track => track instanceof ObjectTrack);
}

function drawBoundingBox(box, name = null, ctx) {
    if (!box) {
        console.warn('No bounding box to draw');
        return;
    }

    // Clamp the bounding box dimensions to stay within canvas bounds
    const clampedX = Math.max(0, Math.min(box.x, ctx.canvas.width));
    const clampedY = Math.max(0, Math.min(box.y, ctx.canvas.height));
    // const clampedWidth = Math.min(box.width, ctx.canvas.width - clampedX);
    // const clampedHeight = Math.min(box.height, ctx.canvas.height - clampedY);

    // Draw the bounding box
    // ctx.strokeStyle = "red";
    // ctx.lineWidth = 2;
    // ctx.strokeRect(clampedX, clampedY, clampedWidth, clampedHeight);

    // Optionally draw the label
    if (name) {
        ctx.shadowBlur = 15;
        ctx.shadowColor = 'goldenrod';
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.fillStyle = 'rgb(116, 12, 12)';
        
        // Clamp label position within canvas bounds
        const labelX = Math.max(0, Math.min(clampedX, ctx.canvas.width - name.length * 20));
        const labelY = Math.max(0, Math.min(clampedY, ctx.canvas.height - 36));

        ctx.fillRect(labelX, labelY, name.length * 20, 36);
        ctx.shadowBlur = 0;
        ctx.shadowColor = 'transparent';
        ctx.fillStyle = "silver";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "bold 35px 'Bebas Neue', sans-serif";
        ctx.fillText(name, labelX + (name.length * 20) / 2, labelY + 20);
    }
}

export function nullableTimeOffsetToSeconds(timeOffset) {
    if (!timeOffset) return 0;

    let seconds = timeOffset.seconds || 0;
    seconds += timeOffset.nanos / 1e9 || 0;
    return seconds;
}


