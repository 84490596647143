import React, { useState, useEffect,} from 'react';
import {Card, Button, CardText, } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import MainTPlayer from '../MainTPlayer' ;
import AH from '../assets/AH.svg';
import MovieList from '../MovieList';
import NewMovies from '../NewMovies';
import EmdMovie from '../emdMovie.js';

function HomeTest() {
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [movies, setMovies] = useState([]);
  const { moviename: movieParam } = useParams();
  const [movieData, setMovieData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(() => {
    const handleBodyClick = (event) => {
      if (selectedTab && !event.target.closest('.expanded-content')) {
        setSelectedTab(null);
        setIsFullScreen(false);
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [selectedTab]);

  useEffect(() => {
    const fetchMovieData = async () => {
      try {
        const response = await fetch(`https://second-team-productions.onrender.com/newmovies`);
        if (!response.ok) {
          throw new Error('Failed to fetch movie data');
        }
        const data = await response.json();
        const filteredMovies = data.filter(movie => movie.moviename === 'SEVEN SAMURAI');
        if (filteredMovies.length > 0) {
          setMovieData(filteredMovies[0]);
          setIsLoaded(true);
        } else {
          throw new Error('Movie data not found');
        }
      } catch (error) {
        console.error('Error fetching movie data:', error);
        setError(error.message);
      }
    };

    fetchMovieData();
  }, [movieParam]);

  return (
    <div style={{ marginBottom: '7%',}}>
      <div>
      <div className='title-section'>
        <div className='row align-items-center'>
          <div className='col-5'> 
              <Card className= 'main-page-content'>
                <h2 className='title-font' 
                style={{ color: movieData && movieData.fontColor,
                  textShadow: movieData && movieData.textShadow }}>
                {movieData && movieData.moviename}
                </h2>
                <div style={{ display: 'flex',  gap: '1rem'  }}>
                {/* {movieData && movieData.rating && (
                    <img  
                     src={movieData.rating} 
                     alt='[Movie Rating]' 
                     className="img-fluid" 
                     style={{
                     width: '3.2vw',
                     height: '3.2vh',
                     marginRight: '12px',
                     }}
                      />
                       )}  */}
                <Card.Text>{movieData && movieData.year}</Card.Text>
                <Card.Text>{movieData && movieData.runtime}</Card.Text>
                </div>
                <Card.Text 
                style={{ marginTop: '2vmin' }}> 
                  {movieData && movieData.moviedescription}
                </Card.Text>
                <Link to={`/${movieData && movieData.moviename}`}>
                  <Button className="process-card-text hover-brighten" 
                         style={{
                          position: 'relative',
                          width: '20vmin',
                          height: '5.5vmin',
                          marginLeft: '10.5vmin',
                          marginTop: '4.5vmin',
                          transform: 'translate(-50%, -50%)',
                          fontSize: '1.8vmin',
                          backgroundColor: 'rgb(67, 66, 66)',
                          border: 'none',
                          cursor: 'pointer',
                        }} 
                  >
                    <h5 className='justify-content-center'
                      style={{
                        margin: "1%",
                      }}> 
                    Watch Now 
                    </h5>
                </Button>
                </Link>
              </Card>
           </div> 
              <div className='col-7'>
              <MainTPlayer/>
              </div>
        </div>
        </div>
      </div>
      <div>
      </div>
      <div className="home-page-body" >
      <div className="row align-items-center">
          <h3 className="d-flex justify-content-left"
          > Featured</h3>
        </div> 
        <div className='container-fluid'>  
        <div className="row align-items-center">
          <div className="justify-content-center"> 
          <p>
            Thank you for visiting 2nd Team's demo site! We're currently developing a way for
            filmmakers and local businesses to partner with one another so they can
            do what they love and get exposure while making money in the process. Click on the poster below
            to explore. We would love your feedback so feel free to send a message with 
            your thoughts. Thanks and enjoy!
          </p>
          </div>
        </div>
      </div>
      
    <div>  
    <div className='container-fluid'>
    <div className='row align-items-center' >
              <EmdMovie movies={movies} setMovies={setMovies} title=" Test " />
        </div>
        <h3 >
          <a href="mailto:nicholas.james.ortiz@secondteamproductions.com">
         <Button 
         className='process-card-text hover-brighten'
         style={{
          marginTop: '7.5%',
          marginBottom:'7.5%',
          fontSize: '2.5vmin',
          backgroundColor: 'rgb(67, 66, 66)',
          border: 'none',
          cursor: 'pointer',
          
        }}
         >Contact Us</Button>
        </a>
          </h3>
    </div>
      </div>
      <div className="row align-items-center">
      <h3 className="d-flex justify-content-left"
          > New Arrivals </h3>
        <div className='container-fluid'>  
        <div className="row align-items-center">
        <NewMovies movies={movies} setMovies={setMovies} title=" New Movies" />
        </div>
      </div>
      </div>
      <div>
    
        <div className="row align-items-center">
        <h3 className="d-flex justify-content-left"
          > Now Playing </h3>
        </div>
        <div className='container-fluid'>
        <div className="row align-items-center">
              <MovieList movies={movies} setMovies={setMovies} title="Movies" />
        </div>  
      
      </div>
      </div>
      <br></br>
      <div>
        <div className="row align-items-center">
        
          <h3 className="d-flex justify-content-center"> The Hitchcock Collection </h3>
        </div>
        <Link to='/HitchcockCollection' style={{ textDecoration: 'none' }}>
        <Button className='container ah-border d-flex justify-content-center align-items-center hover-brighten-hitchcock' style={{ 
          backgroundColor: "rgb(205,0,0)",
          maxWidth: '60%',
          height: '60%',
          border: 'none',
           }}>
        <div  
       >
        <div className="row">
        <div className=' col-6'
        style={{ backgroundColor: "black"}}>
          
        <Card className="d-flex justify-content-center align-items-center"
        style={{
          height: '100%',
          borderColor: "black",
           }}>
            <img className="d-flex" style={{ 
          width: '80%',
          height: '80%',
           }} src={AH} alt="Alfred Hitchcock"
                />
          </Card>
          
          </div>
 <div className='col-6 d-flex justify-content-center align-items-center'>
    <div className='row'>
        <div className='col-12 d-flex justify-content-center'>
            <Card className="d-flex align-items-center"
                style={{
                    borderColor: "rgb(255,0,0)",
                    height: 'auto',
                    minWidth: 'fit-content', 
                }}>
                <CardText>
                    <span className='ah-text' style={{ fontSize: '3vmin', }}>
                        Alfred Hitchcock
                    </span>
                </CardText>
            </Card>
        </div>
        <div className='col-12 d-flex justify-content-center'>
            <Card className='d-flex align-items-center'
                style={{
                    borderColor: "rgb(255,0,0)",
                    height: 'auto',
                    minWidth: 'fit-content', 
                }}>
                <CardText>
                    <span className='ah-text' style={{ fontSize: '2.5vmin' }}>
                        The Master of Suspense
                    </span>
                </CardText>
            </Card>
        </div>
    </div>
</div>
        </div>
        </div>
        </Button>
        </Link>
      </div> 
      <div className='row'
      style={{ 
        marginTop:'5%'
      }}> 
      <Card className='col-10 mx-auto'>
        <CardText 
          style={{
            marginBottom: '2%',
          }}>
        <h3 className='justify-content-center'> 
          Would you like to have your movie on 2nd Team?
           </h3>
           <React.Fragment>
                    <Link to='/Submit' style={{ textDecoration: 'none' }}>
                    <Card className="col-3 mx-auto">
                      <Button className="process-card-text hover-brighten" 
                       style={{
                        marginBottom: '2%',
                        backgroundColor: 'rgb(90, 90, 90)',
                        border: 'none',
                      }}>
                      <h3 className='justify-content-center'
                      style={{
                        margin: "1%",
                      }}
                      > 
                      submit here
                       </h3>
                      </Button>
                    </Card>
                    </Link>
                  </React.Fragment>
        </CardText>
      </Card>
      </div>
      </div>
    </div>
  );
}

export default HomeTest;
