class ObjectTrackCards {
    constructor(objectData, cardWidth, cardHeight) {
        this.name = objectData.entity.description;
        this.price = objectData.entity.price;
        this.details = objectData.entity.details;
        this.startTime = nullableTimeOffsetToSeconds(objectData.segment.start_time_offset);
        this.endTime = nullableTimeOffsetToSeconds(objectData.segment.end_time_offset);
        this.confidence = objectData.confidence;
        this.cardHeight = cardHeight;
        this.cardWidth = cardWidth;

        this.frames = objectData.frames.map(frame => {
            return {
                card: {
               
                    width: this.cardWidth,
                    height: this.cardHeight
                },
                timeOffset: nullableTimeOffsetToSeconds(frame.time_offset)
            };
            
        });

    }

    hasFramesForTime(seconds) {
        return this.startTime <= seconds && this.endTime >= seconds;
    }

    mostRecentRealCard(seconds) {
        for (let i = 0; i < this.frames.length; i++) {
            if (this.frames[i].timeOffset > seconds) {
                return i > 0 ? this.frames[i - 1].card : null;
            }
        }
        return null;
    }

    currentCard(seconds) {
        return this.mostRecentRealCard(seconds);
    }
}

export { ObjectTrackCards };

export function createTrackCards({ cardTracks, video, setCardContent }) {
    if (!Array.isArray(cardTracks)) {
        console.error('Invalid tracks data: Expected an array but received:', typeof cardTracks);
        return;
    }

    if (typeof setCardContent !== 'function') {
        console.error('setCardContent is not a function:', setCardContent);
        return;
    }

    const currentTime = video.currentTime;

    // Map to aggregate card data based on name
    const cardMap = new Map();

    cardTracks.forEach(track => {
        if (track instanceof ObjectTrackCards && track.hasFramesForTime(currentTime)) {
            const displayCard = track.currentCard(currentTime);
            if (displayCard) {
                // Use the name as the unique key
                const cardKey = track.name;

                // Check if we already have a card with the same name
                if (!cardMap.has(cardKey)) {
                    cardMap.set(cardKey, {
                        card: displayCard,
                        name: track.name,
                        price: track.price,
                        details: track.details,
                        confidence: track.confidence
                    });
                }
            }
        } else {
            console.warn('Object is not an instance of ObjectTrackCards:', track);
        }
    });

    // Convert aggregated data to array of card elements
    const contentArray = Array.from(cardMap.values()).map(({ card, name, price, details, confidence }) => 
        createTrackCard(card, name, price, details, confidence)
    );

    setCardContent(contentArray);
}

function createTrackCard(card, name = null, price = null, details = null, confidence = null) {
    if (!card) {
        console.warn('No card to draw');
        return null;
    }

    return (
        <div key={name || Math.random()}>
            <h3>{name || 'Track Card'}</h3>
            <h5>{price !== null && price !== undefined ? price : 'Price not available'}</h5>
            <p>{details !== null && details !== undefined ? details : 'Details not available'}</p>
        </div>
    );
}


export function nullableTimeOffsetToSeconds(timeOffset) {
    if (!timeOffset) return 0;

    let seconds = timeOffset.seconds || 0;
    seconds += timeOffset.nanos / 1e9 || 0;
    return seconds;
}
